import React, { FunctionComponent } from 'react';
import { Row }                 from 'react-bootstrap';

import {
	THasAdditionalClassesOptional,
	THasContentAlignXYOptional,
	THasThemeOptional,
	THasTextAlignOptional,
}                                 from '../../../types/layout';
import { normalizeStyleClasses }  from '../../../utils/helpers';

type _propsType = THasAdditionalClassesOptional & THasContentAlignXYOptional & THasThemeOptional & THasTextAlignOptional

const FullWidthRow: FunctionComponent<_propsType> = (props): React.ReactElement =>
	<Row className={
		normalizeStyleClasses(
			'w-100',
			'mx-0',
			(typeof props.theme === 'string' ? 'theme-' + props.theme : null),
			props.textAlign,
			props.xAlign,
			props.yAlign,
			(props.xAlign || props.yAlign ? 'd-flex' : null),
			...(props.additionalClasses ?? []),
		)
	}>
		{props.children}
	</Row>
;

export default FullWidthRow;
