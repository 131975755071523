import React, {
	FunctionComponent,
}                    from 'react';
import { Container } from 'react-bootstrap';

import { ReactComponent as Logo } from './assets/images/logo.svg';
import FullWidthRow               from './components/common/layout/FullWidthRow';
import { GoQuote }                from 'react-icons/all';
import Column                     from './components/common/layout/bs-wrappers/Column';

import './styles/index.scss';

const App: FunctionComponent = (): React.ReactElement =>
	<>
		<Container fluid={true} className='p-0 text-center'>
			<FullWidthRow theme='primary' xAlign='justify-content-center' additionalClasses={['pt-8', 'pb-6', 'px-6', 'px-sm-0']}>
				<Column span={{sm: 8, md: 7, lg: 6, xl: 5, xxl: 4, xxxl: 3}}>
					<Logo />
				</Column>
			</FullWidthRow>

			<FullWidthRow theme='primary' xAlign='justify-content-center' additionalClasses={['pt-6', 'pb-6', 'px-4', 'px-sm-4']}>
				<Column span={{md: 11, lg: 10, xl: 9, xxl: 7, xxxl: 5}}>
					<h1>A World of Local Music</h1>

					<p className='lead fst-italic'>
						<GoQuote size='1em' className='icon-quote-start' />

						Artists need places to start out, develop, and work on their craft, and small venues have been the cornerstone for
						this. If we don't support live music at this level, then the future of music in general is in danger.

						<GoQuote size='1em' className='icon-quote-end flip-xy' />
					</p>
					<p className='lead tcolor-tangerine'>
						Paul McCartney &mdash; singer, songwriter, working musician
					</p>
				</Column>
			</FullWidthRow>
			<FullWidthRow theme='secondary' xAlign='justify-content-center' yAlign='align-content-center'>
				<Column span={{xs: 7, sm: 6, md: 5, lg: 5, xl: 4, xxxl: 4}} className='mt-5 my-xl-auto p-0'>
					<img src='/images/saxophone-guy.png' alt='Man playing saxophone' />
				</Column>
				<Column
					span={{xs: 11, sm: 10, md: 9, xl: 8, xxxl: 7}}
					className='mb-5 my-xl-6 my-xxxl-auto p-4 ps-xl-5 pe-xl-6 ps-xxl-7 ps-xxxl-8 text-start'
				>
					<h2 className='text-center'>Local music needs our support</h2>
					<p>
						Local music is the heartbeat in every city. Without support, it will fade away.
					</p>
					<p>
						Despite being the <strong>largest</strong> live music segment by location, local live music is absent from streaming networks worldwide.
					</p>
					<p>
						Live, local music is in high demand around the globe, but limited in its availability due to:<br />
						<ul className='mt-4 ps-6 list-style-circle'>
							<li>Production costs</li>
							<li>Logistics</li>
							<li>Simple network access</li>
							<li>Technology barriers and overhead</li>
						</ul>
					</p>
					<p className='lead mt-6 mt-xl-7'>
						This presents a <strong className='tcolor-tangerine'>significant market opportunity.</strong>
					</p>
				</Column>
			</FullWidthRow>
			<FullWidthRow theme='tertiary' xAlign='justify-content-center' yAlign='align-content-center'>
				<Column
					span={{xs: 11, sm: 10, md: 9, xl: 8}}
					className='my-5 my-xl-6 p-4 ps-xl-7 pe-xl-5 ps-xxl-8 ps-xxxl-9 text-start'
				>
					<h2 className='text-center'>Cue MIXO...</h2>
					<p>
						We're reimagining the music industry. Technology first.
					</p>
					<p>
						Through a revolutionary platform of proprietary software and cloud-based tools, we've reduced streaming and
						on-demand production expense to a fraction of current traditional costs while simultaneously offering a wider range of
						easy-to-use features not found in existing platforms.
					</p>
					<p>
						Leveraging global infrastructure, modern software design, and artificial intelligence, MIXO provides an attractive
						gateway for artists and consumers alike.
					</p>
					<p>
						No more technical overhead. With MIXO, artists are free to focus on their music and engagement with their
						audiences. And they don't need to break the bank to do it.
					</p>
					<p>
						Powerful search and recommendation intelligence provides viewers with a endless flow of
						global artists matching their preferences... delivered to any device, anywhere, anytime.
					</p>
					<p className='lead mt-6'>
						MIXO is <span className='tcolor-tangerine'>redefining live music</span> on the world stage.
					</p>
				</Column>
				<Column span={{xs: 7, sm: 6, md: 5, lg: 5, xl: 4}} order={{xs: 'first', xl: 'last'}} className='mt-5 my-xl-auto p-0'>
					<img src='/images/mixo-world.png' alt='Using devices around the world' />
				</Column>
			</FullWidthRow>
			<FullWidthRow theme='secondary' xAlign='justify-content-center' yAlign='align-content-center'>
				<Column span={{xs: 7, sm: 6, md: 5, lg: 5, xl: 4, xxxl: 4}} className='mt-5 my-xl-auto p-0'>
					<img src='/images/spiral-chart.png' alt='Global distribution chart' />
				</Column>
				<Column
					span={{xs: 11, sm: 10, md: 9, xl: 8, xxxl: 7}}
					className='mb-5 my-xl-6 my-xxxl-auto p-4 ps-xl-5 pe-xl-6 ps-xxl-7 ps-xxxl-8 text-start'
				>
					<h2 className='text-center'>Unbounded potential</h2>
					<p>
						MIXO envisions a <strong>global</strong> music industry, seamlessly interacting at all levels.
					</p>
					<p>
						From fans discovering and sharing their favorite new artists, to artists empowered with a platform specifically designed
						to help them achieve their vision... MIXO is applying new and innovative technologies to build value, operate
						efficiently, and generate more revenue.
					</p>
					<p>
						If the history of major technology companies is anything to go by, modern innovation in technology-deprived markets is a
						nearly guaranteed win for those that fill the gap. The potential for revenue streams and services is virtually
						limitless.
					</p>
					<p className='lead mt-6 mt-xl-7'>
						<span className='tcolor-tangerine'>MIXO is the next major innovation.</span>
					</p>
				</Column>
			</FullWidthRow>
			<FullWidthRow theme='primary' xAlign='justify-content-center' additionalClasses={['pb-6']}>
				<Column span={{xs: 12}} className='mt-6'>
					<h2 className='text-center'>The MIXO Team</h2>
				</Column>
				<Column span={{xs: 11, sm: 10, md: 9, xl: 7, xxl: 6}} className='mt-xl-6 mt-xxl-4 px-xxl-8 text-start'>
					<h4 className='text-center tcolor-tangerine'>Tim Harris</h4>
					<h5 className='mb-5 text-center fst-italic'>Digital Strategy and Business Development</h5>

					<p>
						From professional sports to large global brands, Mr. Harris has 20 years experience in digital strategy. Known as an
						innovator, his unique perspective at the intersection of creativity, strategy, technology, and
						customer experience has helped build powerful brands in a fluid, digital industry.
					</p>
					<p>
						Prior to entering the digital sector, Mr. Harris spent a career in creative and account services for large advertising
						firms serving Fortune 500 business accounts as well as popular consumer products.
					</p>
				</Column>
				<Column span={{xs: 11, sm: 10, md: 9, xl: 7, xxl: 6}} className='mt-6 mt-xxl-4 px-xxl-8 text-start'>
					<h4 className='text-center tcolor-tangerine'>Michael James Jackson</h4>
					<h5 className='mb-5 text-center fst-italic'>Media and Entertainment</h5>

					<p>
						Having served as a successful entertainment executive and corporate consultant for over 25 years, Michael is also an
						award-winning record producer and Music Supervisor for film. Known for his work with artists such as KISS, Jesse Colin Young,
						Red Rider, Paul Williams, Pablo Cruise, L.A. Guns, among many others, Jackson's production work has earned him seven gold and
						five platinum record awards.
					</p>
					<p>
						Jackson also co-founded Manuscript Originals along with Graham Nash and several music industry veterans; a company
						designed to honor the classic songs of our times and their authors. Jackson also served as Lead Strategic Advisor to Fanfare
						Entertainment, LLC, a Silicon Valley tech company specializing in the creation of high-value mobile applications to enhance
						existing brands. Clients included Keith Urban, Heart, Bryan Adams, Red fest, Jim Shore and Gander, among others.
					</p>
				</Column>
				<Column span={{xs: 11, sm: 10, md: 9, xl: 7, xxl: 6}} className='mt-6 px-xxl-8 text-start'>
					<h4 className='text-center tcolor-tangerine'>Karen Munster</h4>
					<h5 className='mb-5 text-center fst-italic'>Operations and Finance</h5>

					<p>
						Karen brings over 15 years experience of executive operational management for cloud-based web services, spanning a
						variety of retail and services companies, including McDonald's and Ronald McDonald House Charities.
					</p>
					<p>
						Prior to entering the digital sector, Ms. Munster amassed over 25 years' experience in business operations and fiscal
						management.
					</p>
				</Column>
				<Column span={{xs: 11, sm: 10, md: 9, xl: 7, xxl: 6}} className='mt-6 px-xxl-8 text-start'>
					<h4 className='text-center tcolor-tangerine'>Larry Dennison</h4>
					<h5 className='mb-5 text-center fst-italic'>Technology</h5>

					<p>
						Having built his first computer from spare parts at age 12 and his first software application at age 14, Larry lives and
						breathes technology. He has over 20 years of professional experience in the technology sector across a variety of roles.
						Starting as a junior software engineer before he even graduated school, Larry's self-taught skill and expertise saw him
						promoted to senior engineer in less than six months, and again to technical project manager the following year.
					</p>
					<p>
						Years ahead of most of his colleagues, Larry has served in all levels of management roles and as an executive consultant for
						software agencies of various sizes. For the last 12 years, he has owned and managed his own successful firm focused on
						large-scale, cloud-based platform engineering tailored to global brands and businesses.
					</p>
					<p>
						As someone who understands every layer of a technology business, Larry leads the development and operation of MIXO's
						proprietary software and systems, from start to finish. His technical knowledge and experience with Amazon Web Services
						takes MIXO to the global stage using top-shelf technology at a fraction of traditional costs.
					</p>
				</Column>
			</FullWidthRow>

			<FullWidthRow theme='secondary' xAlign='justify-content-center'>
				<Column className='py-6 my-auto'>
					<h3 className='tcolor-tangerine'>Want to know more?</h3>

					<p className='lead'>Tim Harris &mdash; <a href='mailto:tjharris@mixo.media'>tjharris@mixo.media</a></p>
				</Column>
			</FullWidthRow>
		</Container>
	</>;

export default App;
