import React, { FunctionComponent } from 'react';
import { Col }                      from 'react-bootstrap';
import { normalizeStyleClasses }    from '../../../../utils/helpers';
import { TBreakpointName }          from '../../../../types/layout/TBreakpointName';

type TProperty = 'span'|'offset'|'order';
type TPropertyValueForBreakpoint<Property extends TProperty> = Property extends 'order' ? number | 'first' | 'last' : number;
type TPropertyValue<Property extends TProperty> = {
	[breakpoint in TBreakpointName]?: TPropertyValueForBreakpoint<Property>;
}
type TDefinitionByPropertyAndBreakpoint = {
	[property in 'span'|'offset'|'order']?: {
		[breakpoint in TBreakpointName]?: TPropertyValueForBreakpoint<property>;
	};
}

type THasClassname = {
	className?: string,
}

/**
 * Generates a single combined string of Bootstrap column classes (e.g. `col-lg-3`, `offset-lg-1`, `order-lg-first`, etc.) from `columnSpecs`
 * definition.
 */
const calculateBreakpointClasses = function (columnSpecs: TDefinitionByPropertyAndBreakpoint): string {
	return Object.entries<TPropertyValue<TProperty>>(columnSpecs).map(
		function ([property, columnSpec]): string {
			// Combine breakpoint name and property name to generate Bootstrap style classes for the column
			return Object.entries(columnSpec).map(
				function ([breakpointName, value]): string|null {
					let normalizedParts;

					// Set parts based on the property
					if (property === 'span') {
						normalizedParts = ['col', breakpointName, value];
					}
					else if (property === 'offset' || property === 'order') {
						normalizedParts = [property, breakpointName, value];
					}
					// Return null if the property isn't recognized
					else {
						return null;
					}

					// Join parts together with hyphen separator
					return (
						// Handle special case of 'xs' since it has no prefix in Bootstrap 5
						(breakpointName === 'xs')
							? [normalizedParts[0], normalizedParts[2]]
							: normalizedParts
					).join('-');
				},
			)
				// Filter out any nulls
				.filter(
					(styleClass: string|null): styleClass is string => styleClass !== null,
				)
				.join(' ');
		},
	).join(' ');
};

const Column: FunctionComponent<THasClassname & TDefinitionByPropertyAndBreakpoint> = (props): React.ReactElement =>
	<Col className={
		normalizeStyleClasses(
			calculateBreakpointClasses(props),
			props.className,
		)
	}>
		{props.children}
	</Col>
;

export default Column;
