/**
 * Returns a single combined string of style classes. Each class name is trimmed of whitespace on both sides, and undefined/null values are dropped.
 * For example, an input of:
 * ```
 * normalizeStyleClasses(['container  ', 'p-0      ', undefined, 'mx-1']);
 * ```
 * will return: `container p-0 mx-1`
 */
export const normalizeStyleClasses = (...styleClasses: (string|null|undefined)[]): string => {
	// Drop any style classes that aren't a string or only contain whitespace
	let whitespacePattern = /^\s*$/;
	let filteredStyles = styleClasses.filter(
		(styleClass: any): styleClass is string => typeof styleClass === 'string' && !whitespacePattern.test(styleClass)
	);

	// Trim each style class of surrounding whitespace, join them with a space, and return that combined string
	return filteredStyles
		.map(
			(styleClass: string): string => styleClass.trim()
		)
		.join(' ');
};
